export const members = [
  {
    name: "Rajan Shahi",
    title: "President",
    descriptions: [
      "Originally from Nepal, Rajan Shahi immigrated to Chicago with his family in 2009. They settled in the Edgewater neighborhood for 3 years and then moved to Uptown in 2012. Inspired to help fellow immigrants, in 2010, Rajan started working as a Citizenship Coordinator at Chinese Mutual Aid Association. In this role, Rajan assisted low income immigrants reach their American Dream by becoming naturalized US citizens; as a native speaker of Nepalese and Hindi, he was able to assist many Nepalese and Indian immigrants on their naturalization journey.",
      "With a background in finance, Rajan became CMAA’s Comptroller in 2017, focusing on the financial health of the organization in order to serve more Uptown families in need. Recently promoted, Rajan is currently CMAA’s Director of Finance. Under his financial leadership, Rajan has grown CMAA’s annual operating budget from $5 million to $11 million. Although he no longer works directly with clients, he continues to connect low income immigrant and refugee families to essential services. In 2020, CMAA has helped provide rental and cash assistance to over 200 families through funding from the City of Chicago and foundations. Rajan has helped connect this cash assistance to many Nepalese and other needy families who have lost income due to the pandemic.",
      "Rajan joined the Uptown Chamber of Commerce Board in 2017, and now he is serving as a Board Treasurer. As a former responsible resident of Uptown who has dedicated his career to serving the Uptown immigrant community, Rajan hopes to see the neighborhood be successful and for local businesses to thrive.",
      "Here are some list of Rajan’s contributions to the community:",
    ],
    descriptionList: [
      "Helped more than 30 families become US citizens. Helped most of them to file a fee waiver and they were approved so they didn’t need to pay the USCIS fee which is usually $600-$700 per person",
      "Helped many low income families to apply for food stamp, medicaid, Utility Assistance and other welfares",
      "Hired more than a dozen nepalese and some are still working",
      "During COVID, helped more than 30 families to get $2,000 - $4,000 cash for the rental assistance",
      "During COVID, delivered perishable and no perishable food to many families. Each family got more than 15 lbs or more food",
      "During the earthquake in Nepal, raised over $6,000 and donated to the American Red Cross",
      "Serves as a Board Treasurer for Uptown Chamber of Commerce",
    ],
    imagePath: require("../../../images/rajan.jpg").default,
    videoPath: "https://www.youtube.com/embed/bQ2hmkJ0v2s",
  },
  {
    name: "Sukrins Shrestha",
    title: "Vice President",
    descriptions: [],
    descriptionList: [
      "Actively mentoring the youths in Young Men Making Moves (YMMM)",
      "Actively volunteering in the running community (Carrera de los Muertos & Chicago Marathon)",
      "Fundraised over $800 for Chinese Mutual Aid Association by running a marathon",
      "Volunteered with Rise Against Hunger multiple times to assemble meal kits for the needy",
      "Taught a computer literacy class for a group of elderly",
    ],
    imagePath: require("../../../images/sukrins.jpg").default,
    videoPath: null,
  },
  {
    name: "Saurav Jojiju",
    title: "Treasurer",
    descriptions: [],
    descriptionList: [
      "During Covid 19, helped more the 35 families for rental and cash assistance programs around $2500 and $500 respectively",
      "Helped to file FAFSA for further studies and documentation",
      "Sponsored to get into the USA with DV lottery just as assisted them with finding a new line of work and stay",
      "Helped one for  Home care aids Program",
    ],
    imagePath: require("../../../images/saurav.jpg").default,
    videoPath: null,
  },
  {
    name: "Jitendra Rana",
    title: "Secretary",
    descriptions: [
      "Jitendra Rana migrated to Chicago, IL from Nepal back in 2008. He has been living with his wife and two sons in the Edgewater area. He had been helped by other immigrant peers in his early days, thus he is inspired to serve the community. He has been involved with various Nepali social organizations in the leading role and helped to institutionalize the non profit organization, enhance its record keeping system and set up the accounting system. He has coordinated several relief projects to support disaster victims in Nepal, treatment of sick individuals and support immigrants to settle down in their early days.",
      "He has received his MBA in Accounting from Roosevelt University and started working with Blue Cross Blue Shield of Illinois as an IT quality analyst. He has been practicing his accounting skills via JJ tax and accounting Inc office in Chicago and provides other supplemental services as well.",
      "He has been actively volunteering with Nepalese community organizations in Chicagoland. He had managed more than 200K funds of non-profit organizations for charitable activities and helped several nonprofit organizations to register in state, get ITIN and apply for tax exempt status from the IRS. He led Nepal Earthquake disaster relief fund in 2015 with various organizations and raised around 100K to support disaster victims in Nepal. He has served as Senior VP, Treasurer, Advisor and member in various charitable organizations and running tax and accounting business targeted for Nepalese and immigrant communities.",
      "His Involvement in various non profit community:",
    ],
    descriptionList: [
      "Co-chair, Career Committee – AABRG IL Chapter",
      "Blue Corps Volunteer Ambassador – Blue Corps, BCBSIL",
      "Well-being Champion – Motiva Health, HCSC, Chicago HQ",
      "Treasurer – Toastmasters International, HCSC club 2",
      "Lead – Shark Tank Training Workgroup, mentoring co-workers",
      "Council Member – Asian American Advisory Council, Secretary of State, IL",
      "Advisor – Chicagoland Nepali Friendship Society – Not for Profit, Chicago",
      "Treasurer – International Nepali Literary Society, Chicago, IL Chapter",
      "Treasurer - Palpali Sangam USA - not for profit",
    ],
    imagePath: require("../../../images/jitendra.jpg").default,
    videoPath: "https://www.youtube.com/embed/5DsVUn1jHZc",
  },
];
