/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Inclusive from "../../../images/inclusive.svg";

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box data-aos={isMd ? "fade-right" : "fade-up"}>
      <Box marginBottom={2}>
        <Typography
          variant="h2"
          color={"#545454"}
          sx={{
            fontWeight: 700,
          }}
        >
          NEPALESE{" "}
          <Typography color={"#32b4ff"} component={"span"} variant={"inherit"}>
            AID
          </Typography>
        </Typography>
      </Box>
      <Box marginBottom={3}>
        <Typography
          variant="h5"
          component="p"
          color={"#545454"}
          sx={{ fontWeight: 400 }}
        >
          FOR A STRONG &amp; INCLUSIVE COMMUNITY
        </Typography>
      </Box>

      <Box marginTop={12}>
        <img
          src={Inclusive}
          alt="Inclusive"
          style={{
            borderRadius: 8,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
    </Box>
  );
};

export default Hero;
