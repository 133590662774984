import React from 'react';
import Typed from 'react-typed';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Hero = () => {
  return (
    <Box>
      <Box marginTop={10} marginBottom={4}>
        <Typography
          variant="h5"
          component="p"
          color="text.secondary"
          align={'center'}
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          You can support us and the community by:
        </Typography>
        <Typography
          variant="h3"
          color={"#32b4ff"}
          align={'center'}
          sx={{
            fontWeight: 700,
          }}
        >
          <Typed
            strings={[
              'volunteering',
              'donating',
              'raising funds',
              'educating your network',
              'following our social media',
              'selecting Nepalese Aid on Amazon Smile'
            ]}
            typeSpeed={100}
            loop={true}
            backSpeed={50}
            backDelay={1500}
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default Hero;
