import React from "react";
import Main from "../layouts/Main";
import Container from "../Miscellaneous/Container";
import { Hero, Story, Members } from "./components";
import { mission, objectives, vision } from "./Data";

const About = () => {
  return (
    <Main colorInvert={true}>
      <Hero />
      <Container sx={{ marginBottom: { md: "-5%" } }}>
        <Story {...mission} />
      </Container>
      <Container sx={{ marginBottom: { md: "-2%" } }}>
        <Story {...vision} />
      </Container>
      <Container>
        <Story {...objectives} />
      </Container>
      <Members />
    </Main>
  );
};

export default About;
