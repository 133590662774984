const pages = {
  whoWeAre: [
    {
      title: "About Us",
      href: "/about-us",
    },
    {
      title: "Board Members",
      href: "/about-us#board-members",
    },
    {
      title: "FAQs",
      href: "/faqs",
    },
    {
      title: "Contact Us",
      href: "/contact-us",
    },
  ],
  search: [
    {
      title: "Services",
      href: "/services",
    },
    {
      title: "Community Owned Businesses",
      href: "/community-owned-businesses",
    },
    {
      title: "Community Organizations",
      href: "/community-organizations",
    },
  ],
  getInvolved: [
    {
      title: "How You Can Help Us",
      href: "/how-you-can-help-us",
    },
    {
      title: "Volunteer With Us",
      href: "/volunteer-with-us",
    },
  ],
};

export default pages;
