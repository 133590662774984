import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={1}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Box
            display={"flex"}
            component="a"
            href="/"
            title="theFront"
            width={80}
          >
            <Box
              component={"img"}
              src={require("../../../../images/logo.png").default}
              height={0.75}
              width={0.75}
            />
          </Box>
          <Box
            marginTop={{ xs: 3 }}
            display="flex"
            flexWrap={"wrap"}
            alignItems={"center"}
          >
            <Box marginTop={1} marginRight={2}>
              <Link
                component="a"
                variant="h4"
                color={"#4267B2"}
                href="https://www.facebook.com/nepaleseaid"
                target="_blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                component="a"
                variant="h4"
                color={"#E1306C"}
                href="https://www.instagram.com/nepaleseaid/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={"center"}
          variant={"subtitle2"}
          color="text.secondary"
        >
          &copy; Nepalese Aid
        </Typography>
        <Typography
          align={"center"}
          variant={"caption"}
          color="text.secondary"
          component={"p"}
        >
          For a strong and inclusive community.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
