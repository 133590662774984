import React, { useEffect } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Container from "../../../Miscellaneous/Container";
import BusinessImg from "../../../images/business.jpg";
import ServiceImg from "../../../images/service.jpg";
import OrganizationImg from "../../../images/organization.jpg";

const Hero = (index) => {
  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll(".jarallax");
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import("jarallax");
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });

  return (
    <Box
      className={"jarallax"}
      data-jarallax
      data-speed="0.2"
      position={"relative"}
      minHeight={300}
      display={"flex"}
      alignItems={"center"}
      marginTop={-13}
      paddingTop={13}
      id="agency__portfolio-item--js-scroll"
    >
      <Box
        className={"jarallax-img"}
        sx={{
          position: "absolute",
          objectFit: "cover",
          /* support for plugin https://github.com/bfred-it/object-fit-images */
          fontFamily: "object-fit: cover;",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundImage: `url(${
            index[0] === "nonprofit"
              ? ServiceImg
              : index[0] === "nepaleseBusinesses"
              ? BusinessImg
              : OrganizationImg
          })`,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background: alpha("#161c2d", 0.6),
          zIndex: 1,
        }}
      />
      <Container
        zIndex={3}
        position={"relative"}
        minHeight={300}
        maxHeight={300}
        display={"flex"}
        alignItems={"top"}
        justifyContent={"center"}
      >
        <Box>
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontWeight: 900,
              color: "common.white",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            {index[0] === "nonprofit" ? "Services" : ""}
            {index[0] === "nepaleseBusinesses" ? "Businesses" : ""}
            {index[0] === "nepaleseOrganizations" ? "Organizations" : ""}
          </Typography>

          <Typography
            variant="h6"
            component="p"
            color="text.primary"
            sx={{
              color: "common.white",
              textAlign: "center",
            }}
          >
            {index[0] === "nonprofit"
              ? "Find services from trusted nonprofit organizations"
              : ""}
            {index[0] === "nepaleseBusinesses"
              ? "Find community owned businesses"
              : ""}
            {index[0] === "nepaleseOrganizations"
              ? "Find community organizations"
              : ""}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
