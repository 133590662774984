import React from "react";
import {
  MembersContainer,
  MembersWrapper,
  MembersH1,
  MembersH2,
  MembersP,
  Wrap,
  Content,
} from "./MembersElements";
import { members } from "./Data";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 0.5,
};

const Members = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedMember, setSelectedMember] = React.useState();
  const handleOpen = (member) => {
    setSelectedMember(member);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <MembersContainer id="board-members">
        <MembersH1>Board Members</MembersH1>
        <MembersWrapper>
          {members.map((member, index) => (
            <Wrap>
              <Content key={index} onClick={() => handleOpen(member)}>
                <img src={member.imagePath} alt={member.name} />
                <video autoPlay={true} loop={true} playsInline={true} muted>
                  <source src={member.videoPath} />
                </video>
              </Content>
              <MembersH2>{member.name}</MembersH2>
              <MembersP>{member.title}</MembersP>
            </Wrap>
          ))}
        </MembersWrapper>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              display={"block"}
              width={1}
              height={1}
              sx={{
                textDecoration: "none",
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={4}
                display={"flex"}
                flexDirection={{ xs: "column", md: "row" }}
                sx={{ backgroundImage: "none" }}
              >
                <Box
                  sx={{
                    width: { xs: 1, md: "50%" },
                    position: "relative",
                    "& .lazy-load-image-loaded": {
                      height: 1,
                      display: "flex !important",
                    },
                  }}
                >
                  {selectedMember?.videoPath !== null ? (
                    <iframe
                      height="100%"
                      width="100%"
                      src={selectedMember?.videoPath}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  ) : (
                    <img
                      src={selectedMember?.imagePath}
                      alt={selectedMember?.name}
                      style={{
                        maxWidth: "75%",
                        minWidth: "75%",
                      }}
                    />
                  )}
                </Box>
                <CardContent
                  sx={{
                    position: "relative",
                    width: { xs: 1, md: "50%" },
                    overflow: "auto",
                    height: "100%",
                    padding: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box marginY={4}>
                    <Box marginBottom={2}>
                      <Typography variant={"h4"}>
                        {selectedMember?.name}
                      </Typography>
                      <Typography variant={"p"}>
                        {selectedMember?.title}
                      </Typography>
                    </Box>
                    <Box>
                      {selectedMember?.descriptions.map(
                        (description, index) => (
                          <Typography
                            variant="body2"
                            key={index}
                            sx={{
                              marginBottom:
                                index === selectedMember?.descriptions?.length
                                  ? "5px"
                                  : "35px",
                              lineHeight: "24px",
                            }}
                          >
                            {description}
                          </Typography>
                        )
                      )}
                    </Box>
                    <Box>
                      <ul>
                        {selectedMember?.descriptionList.map(
                          (description, index) => (
                            <li>
                              <Typography
                                variant="body2"
                                key={index}
                                sx={{
                                  marginBottom: "5px",
                                  lineHeight: "24px",
                                }}
                              >
                                {description}
                              </Typography>
                            </li>
                          )
                        )}
                      </ul>
                    </Box>
                  </Box>
                </CardContent>
              </Box>
            </Box>
          </Box>
        </Modal>
      </MembersContainer>
    </>
  );
};

export default Members;
