import React from "react";
import Box from "@mui/material/Box";
import Main from "../layouts/Main";
import Container from "../Miscellaneous/Container";
import { Hero, Solutions, Subscription } from "./components";
import { useTheme } from '@mui/material/styles';

const Enterprise = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box bgcolor={"alternate.main"} marginTop={-13} paddingTop={13}>
        <Container>
          <Hero />
        </Container>
        <Box
          sx={{
            background: "transparent",
            backgroundImage: `linear-gradient(180deg,  ${theme.palette.background.paper} 10%, rgb(247, 250, 255) 0%)`,
          }}
        >
          <Container>
            <Subscription />
          </Container>
          <Container paddingY={"0 !important"} paddingBottom={16}>
            <Solutions />
          </Container>
        </Box>
      </Box>
    </Main>
  );
};

export default Enterprise;
