/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import { alpha } from "@mui/material/styles";
import { colors } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import SchoolIcon from "@mui/icons-material/School";
import { useNavigate } from "react-router-dom";

const mock = [
  {
    color: colors.lightBlue[500],
    title: "Employment Program",
    subtitle:
      "Employment Programs help individuals achieve a sustainable means of living by providing job readiness and placement services to those seeking employment.",
    icon: <WorkIcon />,
  },
  {
    color: colors.purple[500],
    title: "Citizenship & Immigration Case Assistance",
    subtitle:
      "Citizenship and Immigration Program helps immigrants and refugees to become U.S. citizens who are informed and active in their community.",
    icon: <AssuredWorkloadIcon />,
  },
  {
    color: colors.yellow[500],
    title: "Utility Assistance",
    subtitle:
      "The Low Income Home Energy Assistance Program (LIHEAP) helps eligible low-income households pay for home energy services (primarily heating during winter months).",
    icon: <LightbulbIcon />,
  },
  {
    color: colors.indigo[500],
    title: "Food Stamp",
    subtitle:
      "SNAP is a federal benefit designed to help low-income Americans supplement their grocery budgets and purchase healthy food.",
    icon: <LocalGroceryStoreIcon />,
  },
  {
    color: colors.lightBlue[500],
    title: "Medicaid",
    subtitle:
      "Medicaid is a jointly funded state and Federal government program that pays for medical assistance services.",
    icon: <MedicalServicesIcon />,
  },
  {
    color: colors.purple[500],
    title: "Adult Education",
    subtitle:
      "The Adult Literacy Program is an individualized all-volunteer program designed to tutor adult education students and local residents.",
    icon: <SchoolIcon />,
  },
];

const Solutioins = () => {
  const navigate = useNavigate();
  const toServices = (title) => {
    navigate("/services", { state: { id: 1, name: title } });
  };

  return (
    <Box>
      <Box marginTop={8} marginBottom={4}>
        <Typography
          variant="h4"
          align={"center"}
          data-aos={"fade-up"}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Popular Services
        </Typography>
        <Typography
          variant="h6"
          align={"center"}
          color={"text.secondary"}
          data-aos={"fade-up"}
        >
          Select a service to find its provider(s)
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={4}>
          {mock.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Box
                display={"block"}
                width={1}
                height={1}
                sx={{
                  textDecoration: "none",
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  toServices(item.title);
                }}
              >
                <Box
                  component={Card}
                  padding={4}
                  borderRadius={2}
                  width={1}
                  height={1}
                  data-aos={"fade-up"}
                  data-aos-delay={i * 100}
                  data-aos-offset={100}
                  data-aos-duration={600}
                >
                  <Box display={"flex"} flexDirection={"column"}>
                    <Box
                      component={Avatar}
                      width={60}
                      height={60}
                      marginBottom={2}
                      bgcolor={alpha(item.color, 0.1)}
                      color={item.color}
                      variant={"rounded"}
                      borderRadius={2}
                    >
                      <div> {item.icon}</div>
                    </Box>
                    <Typography
                      variant={"h6"}
                      gutterBottom
                      sx={{ fontWeight: 500 }}
                    >
                      {item.title}
                    </Typography>
                    <Typography color="text.secondary" sx={{ fontWeight: 300 }}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Solutioins;
