import React from "react";
import Enterprise from "../Enterprise";

const HomeTwo = () => {

  return (
    <>
      <Enterprise />
    </>
  );
};

export default HomeTwo;
