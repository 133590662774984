import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FaqGroupItem = ({ title, items }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={2}>
        <Typography fontWeight={700} variant={"h5"}>
          {title}
        </Typography>
      </Box>
      <Box>
        {items.map((item, i) => (
          <Box
            component={Accordion}
            key={i}
            padding={1}
            marginBottom={i === item.length - 1 ? 0 : 2}
            borderRadius={`${theme.spacing(1)} !important`}
            sx={{
              "&::before": {
                display: "none",
              },
            }}
          >
            <Box
              component={AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel1a-header--${i}`}
            >
              <Typography fontWeight={600}>{item.title}</Typography>
            </Box>
            <AccordionDetails>
              <Typography color="text.secondary">{item.subtitle}</Typography>
            </AccordionDetails>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FaqGroupItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

const Content = () => {
  return (
    <Box>
      <Box marginBottom={6}>
        <FaqGroupItem
          title={"Basics"}
          items={[
            {
              title: "Who do I contact if I want to volunteer?",
              subtitle:
                "Please fill out your contact information under the volunteer section and someone will contact you within a couple of days. You can also send us a message via Facebook Nepalese Aid page.",
            },
            {
              title:
                "How do I contact Nepalese Aid if I have a question or need help?",
              subtitle:
                "You can contact us via website or Facebook page and we will get back to you within a couple of days.",
            },
            {
              title: "How can I donate to Nepalese Aid?",
              subtitle:
                "Please use the donate button at the top right hand corner and input the payment information.",
            },
            {
              title: "How can I fundraise for Nepalese Aid?",
              subtitle:
                "You can fundraise for Nepalese Aid through social media. You can go to fundraise for a nonprofit and select Nepalese Aid as a beneficiary from the list. You can also message us via website or Facebook and we will create a fundraising page for you.",
            },
            {
              title: "Where will my donation go?",
              subtitle:
                "The initial donation will go primarily on hiring a part-time staff and the office rent.",
            },
            {
              title: "Where is your office located?",
              subtitle:
                "At this moment, we don’t have a physical office location. We are doing fundraising and once we generate the sufficient funds we will explore renting an office space in Edgewater neighborhood.",
            },
            {
              title:
                "Why is my business/organization not listed on the website?",
              subtitle:
                "We have gathered this information from the community members and from google search. If your business/organization is not listed please let us know and we will make sure to add it as soon as possible.",
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Content;
