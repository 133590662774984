import React from "react";
import Box from "@mui/material/Box";

import Main from "../layouts/Main";
import { Hero, Services, Businesses } from "./components";

const BlogReachView = (index) => {
  return (
    <Main colorInvert={true}>
      <Box bgcolor={"alternate.main"} position={"relative"}>
        <Hero {...index} />
        {index[0].includes("nonprofit") ? (
          <Services />
        ) : (
          <Businesses {...index} />
        )}
      </Box>
    </Main>
  );
};

export default BlogReachView;
