import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import HomeTwo from "./pages/index";
import About from "./About";
import BlogReachView from "./BlogReachView";
import PortfolioMasonry from "./PortfolioMasonry";
import ContactPageCover from "./ContactPageCover";
import VolunteerPage from "./VolunteerPage";
import SigninSimple from "./SigninSimple";
import Faq from "./Faq";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NotFound from "./NotFound";

const theme = createTheme({
  palette: { primary: { main: "#32b4ff" }, secondary: { main: "#545454" } },
  typography: {
    fontFamily: [
      "Nunito",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<HomeTwo />} exact />
          <Route path="/about-us" element={<About />} exact />
          <Route path="/faqs" element={<Faq />} exact />
          <Route path="/about-us#board-members" element={<About />} exact />
          <Route
            path="/services"
            element={<BlogReachView {...["nonprofit"]} />}
            exact
          />
          <Route
            path="/community-owned-businesses"
            element={<BlogReachView {...["nepaleseBusinesses"]} />}
            exact
          />
          <Route
            path="/community-organizations"
            element={<BlogReachView {...["nepaleseOrganizations"]} />}
            exact
          />
          <Route
            path="/how-you-can-help-us"
            element={<PortfolioMasonry />}
            exact
          />
          <Route path="/contact-us" element={<ContactPageCover />} exact />
          <Route path="/volunteer-with-us" element={<VolunteerPage />} exact />
          <Route path="/donate" element={<SigninSimple />} exact />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
