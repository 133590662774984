import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const mock = [
  {
    image: require("../../../images/employment.svg").default,
    description:
      "Sign-up to spend a few hours a week helping others with tasks such as: assisting with clients' social service appointments, English/Nepalese language translation,youth mentorship, assisting with the senior social club, and more.",
    title: "Volunteer",
    showButton: true,
    buttonText: "Volunteer",
    href: "/volunteer-with-us",
  },
  {
    image: require("../../../images/donation.svg").default,
    description:
      "Your financial contribution is also tax-deductible. You can write a check or pay online for a one-time gift. Monthly gifts, for example $10 per month, are very impactful. Sign-up to become a monthly donor.",
    title: "Donate",
    showButton: true,
    buttonText: "Donate",
    href: "/donate",
  },
  {
    image: require("../../../images/fund.svg").default,
    description:
      "Encourage others to support Nepalese Aid by creating a Facebook Fundraiser or hosting a house party. Or instead of asking for birthday gifts, tell your friends to direct gifts to Nepalese Aid in your honor.",
    title: "Raise Funds",
    showButton: false,
    buttonText: "",
  },
  {
    image: require("../../../images/educate.svg").default,
    description:
      "Invite people in your network to get to know us. We can schedule a coffee and discussion.",
    title: "Educate Your Network",
    showButton: false,
    buttonText: "",
  },
  {
    image: require("../../../images/socialMedia.svg").default,
    description: "Like us on Facebook. Follow us on Instagram.",
    title: "Follow Our Social Media",
    showButton: true,
    buttonText: "",
  },
  {
    image: require("../../../images/smile.svg").default,
    description:
      "Do you shop on Amazon? Use AmazonSmile instead and select Nepalese Aid as your charity of choice.",
    title: "Amazon Smile",
    showButton: true,
    buttonText: "Go to Amazon Smile",
    href: "https://smile.amazon.com/",
  },
];

const Main = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              component={"a"}
              display={"block"}
              width={1}
              height={1}
              sx={{
                textDecoration: "none",
                transition: "all .2s ease-in-out",
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                display={"flex"}
                flexDirection={"column"}
              >
                <CardMedia
                  image={item.image}
                  title={item.title}
                  sx={{
                    height: { xs: 340, md: 400 },
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0.7)"
                        : "none",
                  }}
                />
                <Box component={CardContent}>
                  <Typography variant={"h6"} fontWeight={700} gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant={"body2"} color="text.secondary">
                    {item.description}
                  </Typography>
                </Box>
                <Box flexGrow={1} />

                {item.showButton ? (
                  <Box component={CardActions} justifyContent={"flex-start"}>
                    {item.title === "Follow Our Social Media" ? (
                      <Box
                        marginTop={{ xs: 3 }}
                        display="flex"
                        flexWrap={"wrap"}
                        alignItems={"center"}
                      >
                        <Box marginTop={1} marginRight={2}>
                          <Link
                            component="a"
                            variant="h4"
                            color={"#4267B2"}
                            href="https://www.facebook.com/nepaleseaid"
                            target="_blank"
                            aria-label="Facebook"
                          >
                            <FaFacebook />
                          </Link>
                        </Box>
                        <Box marginTop={1} marginRight={2}>
                          <Link
                            component="a"
                            variant="h4"
                            color={"#E1306C"}
                            href="https://www.instagram.com/nepaleseaid/"
                            target="_blank"
                            aria-label="Instagram"
                          >
                            <FaInstagram />
                          </Link>
                        </Box>
                      </Box>
                    ) : (
                      <Button
                        size="large"
                        href={item.href}
                        target={item.title === "Amazon Smile" ? "blank" : ""}
                      >
                        {item.buttonText}
                      </Button>
                    )}
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Main;
