/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import Card from "@mui/material/Card";
import { useFormik } from "formik";
import emailjs from "emailjs-com";

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
});

const Subscription = () => {
  const initialValues = {
    name: "",
    email: "",
  };

  const onSubmit = (e) => {
    e.preventDefault();
    formik.validateForm().then((a) => {
      if (Object.keys(a).length === 0) {
        emailjs
          .sendForm(
            "service_bhu6h3a",
            "template_zm288tm",
            e.target,
            "0FhrFUxpcrQCENnOB"
          )
          .then((result) => {
            formik.resetForm();
          });
      } else {
        formik.handleSubmit.apply();
      }
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
  });

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      padding={{ xs: 1, sm: 3 }}
      width={1}
      component={Card}
      boxShadow={1}
      data-aos="fade-up"
    >
      <Box marginBottom={2}>
        <Typography variant="body1" component="p">
          Join the community for our newsletter
        </Typography>
      </Box>
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
          <Box
            width={1}
            marginRight={{ xs: 0, md: 2 }}
            marginBottom={{ xs: 4, md: 0 }}
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
          >
            <TextField
              sx={{
                height: 54,
                marginRight: { xs: 0, md: 2 },
                marginBottom: { xs: 4, md: 0 },
              }}
              variant="outlined"
              color="primary"
              size="medium"
              label="Name"
              fullWidth
              name={"name"}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              sx={{
                height: 54,
              }}
              variant="outlined"
              color="primary"
              size="medium"
              label="Email"
              fullWidth
              name={"email"}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>
          <Box>
            <Button
              sx={{ height: 54, whiteSpace: "nowrap", minWidth: 100 }}
              variant="contained"
              color="primary"
              size="medium"
              fullWidth
              type="submit"
              endIcon={
                <Box
                  component={"svg"}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width={24}
                  height={24}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </Box>
              }
            >
              Subscribe
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default Subscription;
