import styled from "styled-components";

export const MembersContainer = styled.div`
  height:600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(247, 250, 255);

  @media screen and (max-width: 1100px) {
    height: 1000px;
  }

  @media screen and (max-width: 768px) {
    height: 1750px;
  }
`;

export const MembersWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  align-items: 16px;
  padding: 0;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    height: 311px;
    width: 600px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
    height: 311px;
    width: 350px;
  }
`;

export const MembersH1 = styled.h1`
  font-size: 2.5rem;
  color: #4A4A4A;
  margin-bottom: 64px;
  padding-top: 80px;
  margin-bottom: 90px;

  @media screen and (max-width: 1100px) {
    margin-bottom: 70px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 60px;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const MembersH2 = styled.h2`
  color: #4A4A4A;
  font-size: 1rem;
  text-align: center;
  margin-bottom: 10px;
`;

export const MembersP = styled.p`
  color: #4A4A4A;
  font-size: 1rem;
  text-align: center;
`;

export const Wrap = styled.div`
  margin: 15px;

  @media screen and (max-width: 1100px) {
    margin: 25px;
  }
`;

export const Content = styled.div`
  border-radius: 10px;
  border: 3px solid rgb(249, 249, 249);
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;

  img {
    inset: 0px;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: opacity 500ms ease-in-out 0s;
    z-index: 1;
    top: 0;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0px;
    opacity: 0;
    z-index: 0;
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 80%) 0px 40px 58px -16px,
      rgb(0 0 0 / 72%) 0px 30px 22px -10px;
    transform: scale(1.1);
    border-color: rgba(249, 249, 249, 0.8);

    video {
      opacity: 1;
    }
  }
`;
