export const mission = {
  id: "mission",
  headline: "Mission",
  descriptions: [
    "The mission of Nepalese Aid is to serve as a bridge to connect individuals to social services, workforce, legal, health, etc. offered by various organizations, provide youth mentorship, organize the Nepalese community, promote Nepali-owned businesses, create a space for seniors to socialize, and coordinate relief or assistance for Nepal.",
  ],
  img: require("../images/mission.svg").default,
  alt: "Car",
  direction: "row",
};

export const vision = {
  id: "vision",
  headline: "Vision",
  descriptions: [
    "We envision a strong and engaged Nepalese American community that is safe, healthy, and able to achieve its full potential in the U.S.",
  ],
  img: require("../images/vision.svg").default,
  alt: "Piggybank",
  direction: "row-reverse",
};

export const objectives = {
  id: "objectives",
  headline: "Objectives",
  descriptions: [
    "Provide social services offered by local and federal government to the Nepalese and immigrant families living in the Chicagoland",
    "Connect unemployed or underemployed individuals with job trainings, interview and resume preparation support, and employment opportunities through partnerships with workforce development organizations",
    "Assist individuals with the application process for healthcare, immigration, and government benefits",
    "Connect entrepreneurs with business services providers to promote their business and increase their revenue",
  ],
  img: require("../images/objectives.svg").default,
  alt: "Car",
  direction: "row",
};
