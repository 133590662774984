/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import emailjs from "emailjs-com";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Typed from 'react-typed';

const phoneRegExp = /^(\+0?1\s)?\(?\d{3}\)?[-\s]\d{3}[-\s]\d{3,4}$/;

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  lastName: yup
    .string()
    .trim()
    .max(50, "Please enter a valid name")
    .required("Please specify your last name"),
  phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
});

const Contact = () => {
  const [state, setState] = React.useState({
    appointment: false,
    translation: false,
    youth: false,
    senior: false,
  });

  const handleCheckboxChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { appointment, translation, youth, senior } = state;
  const error =
    [appointment, translation, youth, senior].filter((v) => v).length < 1;

  const [inputValue, setInputValue] = useState("");
  const handleInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setInputValue(formattedPhoneNumber);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  };

  const onSubmit = (e) => {
    console.log(e)
    e.preventDefault();
    formik.validateForm().then((a) => {

      console.log(a)

      if (Object.keys(a).length === 0 && !error) {
        emailjs
          .sendForm(
            "service_bhu6h3a",
            "template_1ctsu0q",
            e.target,
            "0FhrFUxpcrQCENnOB"
          )
          .then((result) => {
            formik.resetForm();
            setInputValue("");
            setState({
              appointment: false,
              translation: false,
              youth: false,
              senior: false,
            });
          });
      } else {
        formik.handleSubmit.apply();
      }
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
  });

  return (
    <Box maxWidth={600} margin={"0 auto"}>
      <Box marginBottom={4}>
        <Typography
          variant={"h3"}
          sx={{ fontWeight: 700 }}
          align={"center"}
          gutterBottom
        >
          Volunteer
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          align={"center"}
          gutterBottom
        >
          Sign-up to spend a few hours a week helping others with tasks such as:
        </Typography>
        <Typography
          component="p"
          color={"#32b4ff"}
          align={"center"}
          sx={{ textAlign: "center" }}
        >
          <Typed
            strings={[
              "Assisting with clients' social service appointments",
              "English/Nepalese language translation",
              "Youth mentorship",
              "Assisting with the senior social club",
            ]}
            typeSpeed={100}
            loop={true}
            backSpeed={50}
            backDelay={1500}
          />
        </Typography>
      </Box>
      <Box>
        <form onSubmit={onSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="First name"
                variant="outlined"
                color="primary"
                size="medium"
                name="firstName"
                fullWidth
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Last name"
                variant="outlined"
                color="primary"
                size="medium"
                name="lastName"
                fullWidth
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Phone Number"
                variant="outlined"
                color="primary"
                size="medium"
                name="phone"
                fullWidth
                value={inputValue}
                onChange={(e) => handleInput(e)}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ height: 54 }}
                label="Email"
                type="email"
                variant="outlined"
                color="primary"
                size="medium"
                name="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} md={12} marginTop={1}>
              <Typography variant="h4">Volunteering Opportunities</Typography>
              <Typography variant="caption">
                Select the tasks you are interested in
              </Typography>
              <Box marginTop={1}>
                <FormControl
                  required
                  error={error}
                  component="fieldset"
                  sx={{ m: 3 }}
                  variant="standard"
                >
                  <FormLabel component="legend">Select atleast one</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appointment}
                          onChange={handleCheckboxChange}
                          name="appointment"
                        />
                      }
                      label="Assisting with clients' social service appointments"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={translation}
                          onChange={handleCheckboxChange}
                          name="translation"
                        />
                      }
                      label="English/Nepalese language translation"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={youth}
                          onChange={handleCheckboxChange}
                          name="youth"
                        />
                      }
                      label="Youth mentorship"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={senior}
                          onChange={handleCheckboxChange}
                          name="senior"
                        />
                      }
                      label="Assisting with the senior social club"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>

            <Grid item container justifyContent={"center"} xs={12}>
              <Button
                sx={{ height: 54, minWidth: 150 }}
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Contact;

function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}
