import React from 'react';
import { useTheme } from '@mui/material/styles';

import Main from '../layouts/Main';
import Container from '../Miscellaneous/Container';
import { Hero, Main as MainSection } from './components';

const PortfolioMasonry = () => {
  const theme = useTheme();
  return (
    <Main colorInvert={false}>
      <Hero />
      <Container>
        <MainSection />
      </Container>
    </Main>
  );
};

export default PortfolioMasonry;
