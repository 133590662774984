import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import NavItem from "./components/NavItem";

const SidebarNav = ({ pages }) => {

  const {
    whoWeAre: landingPages,
    search: secondaryPages,
    getInvolved: accountPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={"flex"}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={"img"}
            src={require("../../../../../../images/logo.png").default}
            height={0.7}
            width={0.7}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={"Who We Are"} items={landingPages} />
        </Box>
        <Box>
          <NavItem title={"Search"} items={secondaryPages} />
        </Box>
        <Box>
          <NavItem title={"Get Involved"} items={accountPages} />
        </Box>
        <Box marginTop={10}>
          <Button
            size={"large"}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            href="/donate"
          >
            Donate
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
