/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const Form = () => {
  const gl = document.createElement("script");
  gl.src =
    "https://secure.givelively.org/widgets/simple_donation/nepalese-aid.js?show_suggested_amount_buttons=true&show_in_honor_of=false&address_required=false&has_required_custom_question=false&suggested_donation_amounts[]=25&suggested_donation_amounts[]=50&suggested_donation_amounts[]=100&suggested_donation_amounts[]=250";
  document.getElementsByTagName("head")[0].appendChild(gl);

  return (
    <Box>
      <Box marginBottom={4}>
        <Box>
          <Card sx={{ maxWidth: 345 }}>
            <CardContent sx={{ margin: 3 }}>
              <Box textAlign={"center"}>
                <img
                  src={require("../../../images/logoWithTag.png").default}
                  alt="Inclusive"
                  style={{
                    borderRadius: 8,
                    width: "50%",
                    height: "50%",
                    objectFit: "cover",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                />
              </Box>
              <Box textAlign={"center"} marginY={1}>
                <Typography
                  component="p"
                  color={"#545454"}
                  sx={{ fontSize: "12px" }}
                >
                  FOR A STRONG &amp; INCLUSIVE COMMUNITY
                </Typography>
              </Box>
              <Box textAlign={"center"} marginY={3}>
                <Typography
                  variant="h5"
                  component="p"
                  color={"#545454"}
                  sx={{ fontWeight: 400 }}
                >
                  Support Our Cause
                </Typography>
              </Box>
              <Box>
                <div
                  id="give-lively-widget"
                  class="gl-simple-donation-widget"
                ></div>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default Form;
