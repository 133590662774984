/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Story = ({ headline, descriptions, img, alt, direction }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? direction : "column"}>
        <Grid item container alignItems={"center"} xs={12} md={6}>
          <Box>
            <Typography variant={"h4"} gutterBottom sx={{ fontWeight: 700 }}>
              {headline}
            </Typography>

            {descriptions.map((description, index) => (
              <>
                <Typography
                  key={index}
                  style={{ lineHeight: "24px !important" }}
                >
                  {description}
                </Typography>
                <br />
              </>
            ))}
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={500} width={1}>
            <Box
              component={"img"}
              src={img}
              alt={alt}
              width={0.75}
              height={0.75}
              sx={{
                filter:
                  theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
