import React from "react";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  Stats,
} from "react-instantsearch-dom";
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
} from "react-instantsearch-dom-maps";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import { GridStyle } from "./BusinessesElements";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "../../../Miscellaneous/Container";
import { useLocation } from "react-router-dom";

const searchClient = instantMeiliSearch(
  "http://104.197.248.208/",
  "9ZQ4XlF5a33d4ab9397e9c5b420aca14f2669c76cc0bc66836fb25f9242bb72de34f2a75"
);

const apiKey = "AIzaSyCPKXMEA2P0477rznSIpcY5y4WOFB_AaIA";
const initialZoom = 20;
const initialPosition = {
  lat: 41.881832,
  lng: -87.623177,
};

const Businesses = (index) => {
  window.scrollTo(0, 0);
  const location = useLocation();
  window.history.replaceState({}, document.title);

  return (
    <Box>
      <InstantSearch indexName={index[0]} searchClient={searchClient}>
        <Container
          sx={{
            marginTop: "-5rem",
            position: "relative",
            zIndex: 3,
            paddingY: "0 !important",
          }}
        >
          <Box padding={1} width={1} component={Card} boxShadow={4}>
            <Stats
              translations={{
                stats(nbHits) {
                  return `${nbHits.toLocaleString()} ${
                    nbHits === 1 ? "result" : "results"
                  } found`;
                },
              }}
            />
            <SearchBox />
          </Box>
        </Container>
        <Grid container rowSpacing={1} marginTop={2}>
          <Grid item sm={12} md={6} style={GridStyle}>
            <Hits hitComponent={Hit} />
          </Grid>
          <Grid item sm={12} md={6}>
            <div style={{ minHeight: "100vh" }}>
              <GoogleMapsLoader apiKey={apiKey}>
                {(google) => (
                  <GeoSearch
                    google={google}
                    initialZoom={initialZoom}
                    initialPosition={initialPosition}
                    enableRefine={false}
                    enableRefineOnMapMove={false}
                  >
                    {({ hits }) => (
                      <>
                        {hits.map((hit) => (
                          <>
                            <Marker key={hit.objectID} hit={hit} />
                          </>
                        ))}
                      </>
                    )}
                  </GeoSearch>
                )}
              </GoogleMapsLoader>
            </div>
          </Grid>
        </Grid>
      </InstantSearch>
    </Box>
  );
};

const Hit = ({ hit }) => (
  <div key={hit.objectID} style={{ marginBottom: "10px" }}>
    <List
      key={hit.objectID}
      sx={{ width: "100%", bgcolor: "background.paper" }}
    >
      <ListItem alignItems="flex-start">
        <ListItemIcon>
          <img
            src={hit.imgUrl}
            alt={hit.title}
            style={{ maxWidth: "75px", minWidth: "75px", marginRight: "25px" }}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Highlight attribute="title" hit={hit} />
            </>
          }
          secondary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {hit.address}
                {hit.address ? <br /> : <></>}
                {hit.phone}
                {hit.phone ? <br /> : <></>}
                {hit.email}
              </Typography>
              <br />
              <Link
                href={hit.website}
                underline="hover"
                color="inherit"
                target="_blank"
              >
                {hit.website}
              </Link>
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  </div>
);

export default Businesses;
